<template>
  <div class="animated fadeIn container">
    <h1>발송 상세</h1>
    <table class="border-table mt-4">
      <tr v-if="smsRequest" class="summary-row">
        <td>
          <p>{{ smsRequest.createdAt | dateFormat }}</p>
          <p v-show="summaryInfo.reservationDate != null" style="color:#20a8d8;margin-top:5px;">
            {{ summaryInfo.reservationDate | dateFormat }}
            <button v-show="showReservationCancel" class="btn btn-primary ml-2" @click="clickCancelReservation"
              :disabled="isCancelLoading" style="padding:3px 10px">
              예약취소
            </button>
          </p>
        </td>
        <td>
          {{ smsRequest.message }}
          <button class="hover-btn" style="margin-left: 3px">
            <img src="/img/i_copy.svg" @click="copyToClipboard(smsRequest.message)" class="i-copy" v-b-tooltip.hover
              title="복사하기">
          </button>
        </td>
        <td>
          발송
          <span class="color-skyblue">
            {{ summaryInfo.totalCount }}
          </span>
          / 성공
          <span class="color-skyblue">
            <animate-number :number="summaryInfo.deliveredCount" />
          </span>
          / 대기
          <span class="color-skyblue">
            <animate-number :number="summaryInfo.waitCount" />
          </span>
          / 실패
          <span class="color-skyblue">
            <animate-number :number="summaryInfo.deliveryFailedCount" />
          </span>
        </td>
      </tr>
    </table>

    <ul class="recipient-list mt-4">
      <li :key="item.id" v-for="item in items">
        <span @click="copyToClipboardByAdmin(item.recipient)">{{ item.recipient | phoneFormat }}</span>
        <span class="status-label" v-bind:class="item.class" :title="item.title">{{ item.status }}</span>
      </li>
    </ul>
    <b-pagination align="center" :total-rows="smsRequest.totalCount" v-model="currentPage" :per-page="50" :limit="10"
      class="my-3">
    </b-pagination>
  </div>
</template>

<script>
import SmsService from '@/services/SmsService'
import moment from 'moment'
import { mapGetters } from 'vuex'
import AnimateNumber from '../components/AnimateNumber.vue'

export default {
  name: 'SmsRequest',
  data: () => {
    return {
      isLoading: true,
      isCancelLoading: false,
      items: [],
      limit: 50,
      currentPage: 1,
      smsRequest: {},
      message: null,
      updatedAt: null,
      summaryInfo: {
        totalCount: 0,
        deliveredCount: 0,
        deliveryFailedCount: 0,
      },
      showReservationCancel: false,
      interval: null
    }
  },
  components: {
    AnimateNumber
  },
  mounted() {
    document.body.classList.add('bg-white')

    this.getRequest()
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  },
  methods: {
    clickCancelReservation() {
      if (!confirm('예약문자를 취소하시겠습니까?')) {
        return
      }

      clearTimeout(this.interval);

      var t = this
      t.isCancelLoading = true
      SmsService.cancelReservation(this.$route.params.id)
        .then(() => {
          alert('예약이 취소되었습니다')
          t.$router.push({
            path: '/SmsRequests',
          })
        })
        .catch(function () {
          t.isCancelLoading = false
        })
    },

    isRecent(createdAt) {
      if (!createdAt || createdAt.length == 0) {
        return
      }

      var date = moment().add(-30, 'minutes')
      return date.isBefore(moment(String(createdAt)))
    },
    async getRequest() {
      try {
        clearTimeout(this.interval)
        if (document.visibilityState !== 'visible') {
          return
        }

        const response = await SmsService.getRequest(this.$route.params.id)

        if (response.status === 200 && response.data) {
          this.smsRequest = response.data.smsRequest
          this.summaryInfo = response.data.summaryInfo
          if (this.smsRequest.unit) {
            this.getRequestUnit()
          }

          if (this.summaryInfo.reservationDate != null) {
            this.showReservationCancel = moment().isBefore(moment(this.summaryInfo.reservationDate))
          }
        }
      }
      catch (e) {
        // do nothing
      }
      finally {
        this.interval = setTimeout(() => {
          this.getRequest()
        }, 2000)
      }
    },
    paidMoney: function (count) {
      return parseFloat(count) * 19.8
    },
    async getRequestUnit() {
      var unit = this.smsRequest.unit[this.currentPage - 1]
      this.isLoading = true
      const response = await SmsService.getRequestUnit(this.smsRequest.id, unit.id)
      this.isLoading = false

      var list = response.data.list || []
      var classDic = {
        성공: 'success',
        실패: 'danger',
        대기: 'warning',
      }
      var items = []
      for (var i = 0; i < list.length; i++) {
        var item = list[i]
        items.push({
          recipient: item.recipient,
          status: item.status,
          class: classDic[item.status],
          title: item.status,
        })
      }

      this.items = items
    },
    copyToClipboardByAdmin: function (text) {
      if (this.user.level < 9) {
        return
      }

      this.copyToClipboard(text)
    },
    copyToClipboard: function (text) {
      var textArea = document.createElement("textarea");

      // 복사할 텍스트를 textarea에 설정
      textArea.value = text;

      // body에 textarea 추가
      document.body.appendChild(textArea);

      // textarea를 선택하고 복사 실행
      textArea.select();
      document.execCommand("copy");

      // body에서 textarea 제거
      document.body.removeChild(textArea);
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    currentPage: function () {
      this.getRequestUnit()
    },
    isCancelLoading() { },
  },
}
</script>

<style scoped lang="scss">
.summary-row {
  display: flex;
  align-items: center;
  justify-content: center;

  >td:first-of-type {
    width: 280px;
  }

  >td:nth-of-type(2) {
    flex-grow: 1;
    text-wrap: pretty;
  }

  >td:nth-of-type(3) {
    min-width: 250px;
    white-space: nowrap;
  }

  .i-copy {
    width: 15px;
    opacity: 0.5;
    cursor: pointer;
  }
}

p {
  margin: 0;
}

.link {
  cursor: pointer;
}

.border-table tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.recipient-list {
  width: 100%;
  display: block;
  overflow: hidden;
}

.recipient-list>li {
  position: relative;
  float: left;
  margin: 5px 0.5%;
  width: 19%;
  height: 53px;
  border: 1px solid #cccccc;
  padding: 15px 12px;
  border-radius: 3px;
}

.recipient-list>li>.status-label {
  position: absolute;
  top: 15px;
  right: 12px;
  padding: 3px 10px;
  font-size: 10px;
  border-radius: 2px;
  color: white;
}

.recipient-list>li>.success {
  background-color: #6cba7b;
}

.recipient-list>li>.danger {
  background-color: #e77470;
}

.recipient-list>li>.warning {
  background-color: #f6c244;
}
</style>
